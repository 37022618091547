import React from "react";
import banner from "../../assets/imgs/indiqueganhe/2.jpg";
import bannersm from "../../assets/imgs/indiqueganhe/1.jpg";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import "./indiqueganhe.css";
import img1 from "../../assets/imgs/indiqueganhe/img1.jpg";
import img2 from "../../assets/imgs/indiqueganhe/img2.jpg";
import img3 from "../../assets/imgs/indiqueganhe/img3.jpg";
import ADXIcon from "../../assets/imgs/divmobile.svg";
import ADXCertificate from "../../assets/imgs/indiqueganhe/CertificateADX.svg";
import ContactForm from "../../components/form/ContactFrom";

const indiqueganhe = () => {
  return (
    <div>
      <Header />
      <h1 className="hidden sm:block">
        <div
          style={{
            backgroundImage: `url(${bannersm})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            height: "100vh",
            maxHeight: "820px",
          }}
        >
          {" "}
          <div className="text-left flex flex-col justify-center items-start mx-auto max-w-[90vw] h-full">
            <h1 className="text-customPurple font-sans text-4xl uppercase font-bold">
            Indique potenciais clientes<br/>para a ADX e aumente a sua renda.
            </h1>
            <a href="https://adxcompany.com.br/contato"><button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
              SOLICITE SEU DIAGNÓSTICO
            </button></a>
          </div>
        </div>
      </h1>
      <div className="md:hidden sm:hidden">
        <img src={banner} alt="Banner" className="w-full" />
        <div class="firstsec firstsec-mobile">
          <div className="relative -mt-56 sm:hidden py-10 text-left p-8 ">
            <h1 className="text-customPurple font-sans text-xl font-bold">
            Indique potenciais clientes para a ADX e aumente a sua renda.
            </h1>
            <a href="https://adxcompany.com.br/contato"><button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
              SOLICITE SEU DIAGNÓSTICO
            </button></a>
          </div>
        </div>
      </div>
      <div className="hidden md:block"></div>
      <div className="mx-auto py-20">
        <div className="flex flex-col sm:flex-row text-white sm:grid-cols-3 mx-auto justify-center gap-5 items-center py-10">
          <div className="bg-white p-3 min-h-52 flex flex-col items-center justify-center rounded-xl shadow-lg">
            <img src={img1} className="h-auto w-15 mb-4 self-center" />
            <p className="font-bold text-xs sm:text-2xl text-customPurple text-center">
              Comissões vitalícias
              <br />
              enquanto durar o<br />
              contrato <p className="text-base">*verificar regras</p>
            </p>
          </div>
          <div className="bg-white p-3 min-h-52 flex flex-col items-center justify-center rounded-xl shadow-lg">
            <img src={img2} className="h-auto w-15 mb-4 self-center" />
            <p className="font-bold text-xs sm:text-2xl text-customPurple text-center">
            Indique empresas<br/>dos nossos segmentos
            </p>
          </div>
          <div className="bg-white p-3 min-h-52 flex flex-col items-center justify-center rounded-xl shadow-lg ">
            <img src={img3} className="h-auto w-15 mb-4 self-center" />
            <p className="font-bold text-xs sm:text-2xl text-customPurple text-center">
              Nossa taxa de
              <br />
              retenção de clientes
              <br />é de 87%
            </p>
          </div>
        </div>
      </div>
      <div className="bg-customPurple grid sm:grid-cols-2 py-20">
        <div className="sm:hidden block items-center flex flex-col pb-10">
          {" "}
          <img src={ADXIcon} className="self-center h-10" />
        </div> 
        <div className="text-4xl sm:text-6xl font-bold text-white items-center justify-center flex pb-16 sm:pb-0">
          Quem pode
          <br />
          fazer parte?
        </div>
        <div className="text-2xl sm:text-3xl text-white text-center sm:text-left">
          <div className="flex flex-col sm:flex-row items-center">
            <img src={ADXIcon} className="h-10 hidden sm:block" />
            <p className="sm:pl-3 sm:font-bold">Consultores de segurança</p>
          </div>
          <div className="flex flex-col sm:flex-row items-center pt-10">
            <img src={ADXIcon} className="h-10 hidden sm:block" />
            <p className="sm:pl-3 sm:font-bold">
              Consultores de facilities e terceirização
            </p>
          </div>
          <div className="flex flex-col sm:flex-row items-center pt-10">
            <img src={ADXIcon} className="h-10 hidden sm:block" />
            <p className="sm:pl-3 sm:font-bold">Consultores de food service</p>
          </div>
          <div className="flex flex-col sm:flex-row items-center pt-10">
            <img src={ADXIcon} className="h-10 hidden sm:block" />
            <p className="sm:pl-3 sm:font-bold">Empresas de tecnologia</p>
          </div>
          <div className="flex flex-col sm:flex-row items-center pt-10">
            <img src={ADXIcon} className="h-10 hidden sm:block" />
            <p className="sm:pl-3 sm:font-bold">
              Representantes comerciais e afins
            </p>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-center items-center sm:min-h-screen sm:py-0 py-20 bg-gray-100">
        <button className="bg-purple-600 text-white py-2 px-4 rounded-full shadow-md hover:bg-purple-700 mb-20 sm:mb-32 sm:text-3xl font-bold sm:mx-0 mx-20">
          Vamos dar os próximos passos juntos?
        </button>

        <div className="max-w-3xl p-8 bg-white shadow-lg rounded-lg relative mx-14 sm:mx-auto">
          <div className="absolute -right-10 -top-10 sm:-right-32 sm:-top-32">
            {/* Placeholder for ADX Indica logo */}
            <img src={ADXCertificate} className="h-32 sm:h-auto"/>
          </div>
          <div className="mt-12 text-center">
            <h2 className="text-2xl sm:text-5xl font-bold">Modelo de parceria</h2>
            <h3 className="text-purple-600 text-2xl sm:text-5xl font-semibold">
              Indicação
            </h3>
            <ul className="text-center sm:mt-4 space-y-2 text-1xl sm:text-3xl pt-10">
              <li>• Responsável por gerar interesse</li>
              <li>• Realizar pequeno briefing sobre a solução</li>
              <li>• Garantir que os clientes compareçam às apresentações</li>
              <li>• Auxiliar no fechamento do contrato</li>
            </ul>
          </div>
        </div>
      </div>

      <div className="py-20 sm:mx-auto mx-10">
        <ContactForm/>
      </div>
      <Footer />
    </div>
  );
};

export default indiqueganhe;
