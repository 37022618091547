import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./home.css";
import img1 from "./../../assets/imgs/home/10.jpg";
import img2 from "./../../assets/imgs/home/11.jpg";
import img3 from "./../../assets/imgs/home/12.jpg";
import img4 from "./../../assets/imgs/home/13.jpg";
import { Navigation } from "swiper/modules";

const testimonials = [
  {
    text: 
      "“Tenho notado melhorias na ferramenta, como a checklist customizada, que traz grande flexibilidade para nossos processos operacionais. Além disso, tanto eu quanto minha equipe elogiamos o suporte de vocês, sempre atencioso e eficiente na resolução de dúvidas, o que se destaca em relação a outros sistemas que usamos.”",
    author: "Natalia Espindola",
    position: "Gerente de operações",
    company: "Grupo Liderança",
    icon: img4,
  },
  {
    text: "“A ferramenta da ADX Company para gestão operacional, é uma ótima para controle e gestão de clientes, equipes e materiais.\nAgora, temos a possibilidade de acompanhar com maior precisão toda nossa rotina de serviços, bem como a melhora no nosso planejamento de atividades.”",
    author: "Douglas",
    position: "Coordenador operacional",
    company: "Definitiva Terceirização",
    icon: img3,
  },
  {
    text: "“Desde que iniciamos com a plataforma da ADX Company, nosso serviço mudou! Conseguimos realizar nossas atividades com mais transparência para os clientes, além da praticidade de emitir relatórios com os resultados obtidos mensalmente.\nAinda temos o controle dos funcionários, o que deixou nosso serviço mais organizado.”",
    author: "Emerson Caldas",
    position: "Coordenador operacional",
    company: "Souza e Filhos",
    icon: img2,
  },
  {
    text: "Desde que iniciamos com a plataforma da ADX Company, nosso serviço mudou!\nConseguimos realizar nossas atividades com mais transparência para os clientes, além de emitir relatórios com os resultados obtidos em nosso trabalho.\nAinda temos o controle dos funcionários, o que deixou nosso serviço mais organizado.",
    author: "Thiago",
    position: "Analista de sistemas",
    company: "Segvap",
    icon: img1,
  },
];

const Carousel = () => {
  return (
    <div className="bg-customPurple pt-10 pb-20 md:pb-32 bg-pattern">
      <div className="container mx-auto pb-11 max-w-[90vw] justify-between justify-items-center">
        <Swiper
          modules={[Navigation]}
          spaceBetween={20}
          slidesPerView={3}
          navigation
          loop={true}
          breakpoints={{
            1024: {
              slidesPerView: 3, // Desktop: 3 slides
            },
            600: {
              slidesPerView: 2, // Tablet: 2 slides
            },
            0: {
              slidesPerView: 1, // Mobile: 1 slide
            },
          }}
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="p-4">
                <div className="bg-white p-6 rounded-lg shadow-lg min-h-[400px] flex flex-col justify-between">
                  {testimonial.text.split('\n').map((line, i) => (
                    <p key={i} className="text-gray-700 mb-4">
                      {line}
                    </p>
                  ))}
                  <div className="mt-auto flex items-center">
                    <img
                      src={testimonial.icon}
                      alt="Icon"
                      className="w-8 h-8 mr-2"
                    />
                    <div>
                      <div className="text-customPurple font-semibold">
                        {testimonial.author}
                      </div>
                      <div className="text-gray-500 text-sm">
                        {testimonial.position}, {testimonial.company}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default Carousel;
