import React from "react";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";

const sobre = () => {
  return (
    <div>
      <Header />
      <div className="bg-customPurple py-20 bg-pattern flex justify-center relative">
        <div className="md:w-full md:max-w-[90vw] p-8 grid gap-4 items-center">
          <div className="flex flex-col sm:text-left justify-center items-center">
            <h1 className="text-4xl text-white font-bold">
              Sobre a ADX Company
            </h1>
            <p className="text-xl text-white pt-4 leading-relaxed text-center">
              Desde 2015 escrevendo uma trajetória de inovação em soluções digitais
            </p>
          </div>
        </div>
      </div>
      <div className="bg-white py-20 text-customPurple flex flex-col justify-center items-center">
        <p className="md:max-w-[70vw] px-10">
        Somos uma empresa de tecnologia fundada em 2015, sediada no Polo Tecnológico do Recife. A <b>ADX Company</b> surgiu como uma software house voltada para diversos segmentos, e se especializou na construção de soluções para a gestão operacional. Nossos produtos e projetos possuem foco na transformação digital, fazendo com que o modus operandi da sua empresa seja 100% refletido em sistemas práticos, seguros e consistentes.
        <br/><br/>
Desde então, a <b>ADX Company</b> vem atuando de forma expressiva nas áreas de segurança privada, terceirização de mão de obra, segurança privada, gestão de resíduos e food service. Hoje, as nossas soluções estão presentes em 24 estados do Brasil, além de contar com mais de um milhão de usuários que utilizam os nossos sistemas diariamente.
        </p>
      </div>
      <div className="bg-customPurple py-20 bg-pattern flex justify-center relative"></div>

      <section class=" text-white py-20 -mt-32 z-20">
    <div class="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-6 px-4">

      <div class="z-20 bg-white text-purple-700 p-6 rounded-lg shadow-lg">
        <h2 class="text-2xl font-bold mb-4 text-center">Missão</h2>
        <p class="text-center">
          Conduzir a evolução da operação dos nossos clientes com transparência, simplicidade e inovação.
        </p>
      </div>

      <div class="z-20 bg-white text-purple-700 p-6 rounded-lg shadow-lg">
        <h2 class="text-2xl font-bold mb-4 text-center">Visão</h2>
        <p class="text-center">
          Nos consolidarmos como referência em desenvolvimento de soluções tecnológicas emergentes para a gestão operacional no Brasil, sempre proporcionando um ambiente confortável, colaborativo, criativo e inovador para nosso time.
        </p>
      </div>

      <div class="z-20 bg-white text-purple-700 p-6 rounded-lg shadow-lg">
        <h2 class="text-2xl font-bold mb-4 text-center">Valores</h2>
        <ul class="list-none text-center">
          <li>Comprometimento</li>
          <li>Aprendizado Contínuo</li>
          <li>Foco e Dedicação</li>
          <li>Colaboração</li>
          <li>Alegria</li>
          <li>Singularidade</li>
          <li>Audácia</li>
        </ul>
      </div>

    </div>
  </section>

      <Footer />
    </div>
  );
};

export default sobre;
