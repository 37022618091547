// src/ContactForm.js
import React, { useState } from 'react';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    setor: '',
    name: '',
    email: '',
    telefone: '',
  });

  const [status, setStatus] = useState('');

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('/api/formHandler', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setStatus('Form submitted successfully!');
        setFormData({ setor: '', name: '', email: '', telefone: '' });
      } else {
        setStatus('Form  submission failed. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setStatus('Form submission failed. Please try again.');
    }
  };

  return (
    <div className="max-w-md mx-auto p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl sm:text-5xl font-bold text-center mb-4 text-purple-700">Cadastre-se</h2>
      <div className='hidden'>{status && <p className="text-center mb-4 text-red-500">{status}</p>}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
        
          <select 
            name="setor"
            id="setor"
            value={formData.setor}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            required
          >
            <option value="" disabled>Setores</option>
            <option value="Setor1">Segurança privada ou Terceirização de serviços.</option>
            <option value="Setor2">Food service ou Restaurantes corportivos.</option>
            <option value="Setor3">Coleta de resíduos.</option>
          </select>
        </div>
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="name">
            Nome
          </label>
          <input
            type="text"
            name="name"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="email">
            Email
          </label>
          <input
            type="email"
            name="email"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700" htmlFor="telefone">
            Telefone
          </label>
          <input
            type="text"
            name="telefone"
            id="telefone"
            value={formData.telefone}
            onChange={handleChange}
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-400"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full bg-purple-600 text-white py-2 px-4 rounded-md hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-purple-400"
        >
          Enviar
        </button>
      </form></div>
      <iframe
                src="https://blog.adxcompany.com.br/?page_id=288"
                width="600"
                height="450"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                className="w-full max-w-lg md:max-w-3xl h-96 rounded-l-lg"
                title="Google Maps - ADX Company"
              />
    </div>
  );
};

export default ContactForm;
