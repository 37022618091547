import React from "react";
import banner from "../../assets/imgs/gestao/mob.png";
import BannerComponent from "../../components/rotatebanner/bannercomponent3";
import imggestao from "../../assets/imgs/gestao/4.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import YouTubeVideo from "../../components/youtubevideo";
import imgphone from "../../assets/imgs/gestao/5.jpg";

const gestao = () => {
  return (
    <div>
      <div className="hidden md:block"></div>
      <div className="mx-auto pb-20">
        <Header />
        <BannerComponent />
        <div className="md:hidden">
          <img src={banner} alt="Banner" className="w-full" />
          <div class="firstsec firstsec-mobile">
            <div className="relative -mt-80 sm:hidden py-10 text-left p-8">
              <h1 className="text-customPurple font-sans text-xl">
              <b>OTIMIZE O CONTROLE</b><br/>DA SUA <b>OPERAÇÃO </b>DE<br/><b>COLETA DE RESÍDUOS</b>
              </h1>
              <p className="pt-4 text-customPurple">
              Facilite o processo de criação e gestão das rotas e solicitações do setor logístico, tornando a operação mais eficiente.
              </p>
              <a href="https://adxcompany.com.br/contato"><button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
                SOLICITE SEU DIAGNÓSTICO
              </button></a>
            </div>
          </div>
        </div>
        <div className="bg-customPurple py-10 bg-pattern flex flex-col items-center">
          <div className="items-center md:w-full  md:max-w-[90vw] md:grid md:grid-cols-2 z-40 p-8">
            <div>
              <p className="flex flex-col text-white sm:text-left justify-center h-full sm:pr-10 sm:text-xl">
              Tenha total controle do seu estoque de recipientes: <br />

Cadastre os itens do seu jeito, de acordo com a sua realidade;<br />

Gerencie os itens no estoque, em processo de higienização e em rota, de forma prática e eficiente; e muito mais!
              </p>
            </div>
            <div className="flex flex-col">
              {" "}
              <div>
                <img
                  src={imggestao}
                  alt="dashboard"
                  className="pt-10 sm:pt-0 z-10 h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-8">
          <h1 className="text-2xl font-bold text-customPurple pb-4 sm:text-4xl sm:pt-14 ">
            Soluções para<br/>qualquer operação
          </h1>
          <p className="mt-2 text-black font-normal sm:hidden">
            Entre elas estão:
          </p>
        </div>
        <div className="bg-gray-100 mx-auto items-center pt-7 px-[6vw] pb-4 ">
  <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
    
    <div className="flex flex-col items-center bg-white p-4 rounded-lg shadow ">
      <div className="flex text-customPurple font-semibold sm:text-xl">
        <p className="sm:pb-4">ROTAS E ROTAS EXTRAS</p>
        
      </div>
    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow ">
      <div className="text-customPurple font-semibold sm:text-xl">
      <p className="sm:pb-4">SOLICITAÇÃO DE DEMANDA</p>
      </div>

    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow">
      <div className="text-customPurple font-semibold sm:text-xl">
      <p className="sm:pb-4">INCONSISTÊNCIAS</p>
      </div>

    </div>

  </div>

  <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
    
    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow ">
      <div className="text-customPurple font-semibold sm:text-xl">
      <p className="sm:pb-4">CONFERÊNCIA DE PESO</p>
      </div>

    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow ">
      <div className="text-customPurple font-semibold sm:text-xl">
      <p className="sm:pb-4">ROMANEIO DIÁRIO</p>
      </div>

    </div>

    <div className="flex flex-col items-left bg-white p-4 rounded-lg shadow ">
      <div className="text-customPurple font-semibold sm:text-xl">
      <p className="sm:pb-4">GERENCIAMENTO DE RECIPIENTE</p>
      </div>

    </div>

  </div>
</div>

        <h1 className="m-8 text-2xl font-bold text-customPurple mb-4 mt-20 sm:text-4xl">
        MULTIPLATAFORMA
        </h1>
        <p className="px-5 mx-4 md:mx-[20vw] sm:text-2xl">
        Através de funcionalidades mobile, permita que as informações sejam recolhidas sem a necessidade de papéis ou do retorno a um computador.
        </p>
        <div className="flex flex-col items-center mt-24">
          <img
            src={imgphone}
            alt="ADX Dashboard"
            className="h-[40vw] md:h-60 w-auto -mt-14"
          />
        </div>
       
        <div className="md:-mt-0 flex flex-col items-center">
          <div className="mt-20 sm:-mt-0 container px-6 md:px-48 md:py-8 z-40">
            {" "}
            <YouTubeVideo videoId="E-Wc9FFdsnE" />
            <p className="px-7 hidden sm:block pt-10 sm:text-2xl">
        Tenha em mãos vários tipos de relatórios gerenciais, desde o acompanhamento do planejado e o executado, as coletas que acontecerem no dia, movimentações, excesso de peso, dentre tantos outros
        </p>
          </div>
        </div>

      </div>
      <Footer />

    </div>
  );
};

export default gestao;
 