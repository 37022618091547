import React, { useState, useEffect } from "react";
import logo from "../../assets/imgs/logo001.png";
import MegaMenu from "./megaseg"; // Assuming MegaMenu is in the same folder
import Icon from "../../assets/imgs/iconheader.svg";
import Svg1 from "../../assets/imgs/svg1.svg";
import Svg2 from "../../assets/imgs/svg2.svg";
import Svg3 from "../../assets/imgs/svg3.svg";

const Header = () => {
  const [isMegaMenuOpen, setMegaMenuOpen] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isGestoresPageOpen, setIsGestoresPageOpen] = useState(false); // New state for "Para gestores" page

  const handleMouseEnter = () => {
    setMegaMenuOpen(true);
  };

  const handleMouseLeave = () => {
    setMegaMenuOpen(false);
  };

  useEffect(() => {
    const handleScroll = (event) => {
      // Check if the scroll target is not the side menu
      if (isMenuOpen && !event.target.closest(".side-menu")) {
        event.preventDefault();
      }
    };

    if (isMenuOpen) {
      document.body.classList.add("overflow-hidden");

      // Add event listeners to prevent scrolling on body but allow scrolling inside the side menu
      window.addEventListener("wheel", handleScroll, { passive: false });
      window.addEventListener("touchmove", handleScroll, { passive: false });
    } else {
      document.body.classList.remove("overflow-hidden");

      // Remove event listeners when not needed
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    }

    // Cleanup function to remove event listeners when component unmounts or isMenuOpen changes
    return () => {
      document.body.classList.remove("overflow-hidden");
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchmove", handleScroll);
    };
  }, [isMenuOpen]); // Run this effect when 'isMenuOpen' changes

  return (
    <div>
      <div className="hidden sm:block">
        <header className="relative z-20 my-8">
          {" "}
          {/* Adjusted z-index */}
          {/* Header */}
          <div className="flex items-center justify-between max-w-[90vw] mx-auto h-full">
            <div className="flex items-center">
              <a href="../">
                <img
                  src={logo}
                  alt="Logo"
                  className="h-14 sm:h-10 hover:scale-105 transition-transform duration-100"
                />
              </a>
              <span className="ml-2 text-lg font-semibold"></span>
            </div>
            <div className="flex text-xl space-x-8 text-purple-600 z-50">
              <div
                className="flex flex-row items-center"
                onMouseEnter={(e) => {
                  handleMouseEnter(e);
                  const img = e.currentTarget.querySelector("img");
                  const link = e.currentTarget.querySelector("a");
                  img.style.transform = "rotate(90deg)";
                  link.style.textDecoration = "underline"; // Add underline on hover
                }}
                onMouseLeave={(e) => {
                  handleMouseLeave(e);
                  const img = e.currentTarget.querySelector("img");
                  const link = e.currentTarget.querySelector("a");
                  img.style.transform = "rotate(-90deg)";
                  link.style.textDecoration = "none"; // Remove underline when not hovering
                }}
              >
                <a href="#">Segmentos</a>
                <img
                  src={Icon}
                  className="h-3 -rotate-90"
                  style={{
                    transition: "transform 0.5s ease",
                  }}
                />
              </div>

              <a
                href="http://blog.adxcompany.com.br/"
                className="hover:underline"
              >
                Aprenda
              </a>
              <a href="indiqueganhe" className="hover:underline">
                Indique e Ganhe
              </a>
              <a href="sobre" className="hover:underline">
                Sobre
              </a>
              <a href="contato" className="hover:underline">
                Contato
              </a>
            </div>
            <div>
              <a href="https://adxcompany.com.br/contato">
                <button className="bg-purple-600 text-white py-4 px-6 rounded-full font-bold">
                  TESTE GRÁTIS
                </button>
              </a>
            </div>
          </div>
          {/* Mega Menu */}
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <MegaMenu isOpen={isMegaMenuOpen} />
          </div>
        </header>
      </div>

      {/* Mobile Navbar */}
      <div className="flex flex-row sm:hidden items-center justify-between p-4 bg-white z-20 relative">
        <div className="flex items-center">
          <div className="text-white rounded-full p-2">
            <a href="../">
              <img src={logo} className="h-6" />
            </a>
          </div>
        </div>

        <div className="flex items-center ml-20">
          {" "}
          {/* Added margin-left to move it right */}
          <button className="bg-purple-600 text-white py-2 px-4 rounded-full font-bold text-sm">
            <a href="https://adxcompany.com.br/contato">Teste Grátis</a>
          </button>
        </div>

        <div className="flex items-center">
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="focus:outline-none"
          >
            <div className="space-y-1">
              <div className="w-6 h-0.5 bg-purple-600"></div>
              <div className="w-6 h-0.5 bg-purple-600"></div>
              <div className="w-6 h-0.5 bg-purple-600"></div>
            </div>
          </button>
        </div>
      </div>

      {/* Side Menu */}
      <div
        className={`side-menu sm:hidden fixed top-[4.5rem] right-0 w-full h-[calc(100vh-4.5rem)] bg-slate-200 shadow-lg transform transition-transform duration-300 z-10 ${
          isMenuOpen ? "translate-x-0" : "translate-x-full"
        } overflow-y-auto`}
      >
        {!isGestoresPageOpen ? (
          <div className="p-4 text-left mr-4 z-50">
            <h2 className="text-purple-700 font-bold">Segmentos</h2>
            <ul className="mt-4">
              <li className="mt-2 bg-white rounded-lg p-4">
                <a
                  href="#"
                  className="text-gray-800 hover:text-purple-600"
                  onClick={() => setIsGestoresPageOpen(true)} // Open "Para gestores" page
                >
                  Para gestores
                </a>
                <p>Veja soluções feitas para gestores de equipe de campo</p>
              </li>
              <li className="mt-2 pl-4">
                <a
                  href="http://blog.adxcompany.com.br/"
                  className="text-gray-800 hover:text-purple-600"
                >
                  Aprenda
                </a>
              </li>
              <li className="mt-2 pl-4">
                <a
                  href="indiqueganhe"
                  className="text-gray-800 hover:text-purple-600"
                >
                  Indique e Ganhe
                </a>
              </li>
              <li className="mt-2 pl-4">
                <a
                  href="sobre"
                  className="text-gray-800 hover:text-purple-600"
                >
                  Sobre
                </a>
              </li>
              <li className="mt-2 pl-4">
                <a
                  href="contato"
                  className="text-gray-800 hover:text-purple-600"
                >
                  Contato
                </a>
              </li>
            </ul>
          </div>
        ) : (
          <div className="p-4 text-left h-full pb-20 overflow-y-auto">
            {" "}
            {/* Added overflow-y-auto here */}
            {/* Content for "Para gestores" page */}
            <button
              className="text-purple-700 font-bold mb-4"
              onClick={() => setIsGestoresPageOpen(false)} // Go back to the main menu
            >
              &larr; Voltar
            </button>
            <h2 className="text-purple-700 font-bold">Para Gestores</h2>
            {/* Insert your custom content here */}
            <p>Veja soluções feitas para gestores de equipe de campo</p>
            <div className="pt-4 space-y-6 text-left">
              <a href="/terceirizacao">
                <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 rounded-lg border border-solid border-slate-200 shadow-lg">
                  <img
                    src={Svg1}
                    alt="My Icon"
                    className="w-8 h-8 text-purple-500 mr-3"
                  />

                  <div>
                    <h4 className="text-purple-600 font-semibold">Segurança Privada e Terceirização de Serviços</h4>
                    <p className="text-gray-600">
                      Software para medir, evidenciar e gerenciar atividades
                      para operações de Segurança e Terceirização de Serviços.
                    </p>
                  </div>
                </div>
              </a>

              <a href="/conselho">
                <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 rounded-lg border border-solid border-slate-200 shadow-lg mt-4">
                  <img
                    src={Svg1}
                    alt="My Icon"
                    className="w-8 h-8 text-purple-500 mr-3"
                  />

                  <div>
                    <h4 className="text-purple-600 font-semibold">
                      Conselhos de Classe
                    </h4>
                    <p className="text-gray-600">
                      Software especializado em gestão de operações,
                      desenvolvido para atender todas as etapas do processo de
                      fiscalização, desde a denúncia à elaboração de relatório.
                    </p>
                  </div>
                </div>
              </a>
              <a href="/food">
                <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 rounded-lg border border-solid border-slate-200 shadow-lg mt-4">
                  <img
                    src={Svg2}
                    alt="My Icon"
                    className="w-8 h-8 text-purple-500 mr-3"
                  />

                  <div>
                    <h4 className="text-purple-600 font-semibold">Food Service e Restaurantes Corporativos</h4>
                    <p className="text-gray-600">
                      Plataforma completa para gerenciar as operações da sua
                      empresa de refeição coletiva.
                    </p>
                  </div>
                </div>
              </a>
              <a href="/gestao">
                <div className="flex items-center p-4 bg-slate-100 bg-opacity-50 rounded-lg border border-solid border-slate-200 shadow-lg mt-4">
                  <img
                    src={Svg3}
                    alt="My Icon"
                    className="w-8 h-8 text-purple-500 mr-3"
                  />

                  <div>
                    <h4 className="text-purple-600 font-semibold">Coleta de Resíduos Sólidos</h4>
                    <p className="text-gray-600">
                      Sistema especializado no segmento de coleta e transporte
                      de resíduos sólidos.
                    </p>
                  </div>
                </div>
              </a>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
