import React from "react";
import banner from "../../assets/imgs/Vigilante-Patrimonial.jpg";
import BannerComponent from "../../components/rotatebanner/bannercomponent4";
import imgconselho from "../../assets/imgs/conselho/ban_conselho.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import Img1 from "../../assets/imgs/conselho/DENUNCIA.jpg";
import Img2 from "../../assets/imgs/conselho/GESTAO DE ANUNCIOS.jpg";
import Img3 from "../../assets/imgs/conselho/APP FISCALIZACAO.jpg";
import Img4 from "../../assets/imgs/conselho/RELATORIO.jpg";
import YouTubeVideo from "../../components/youtubevideo";

const terceirizacao = () => {
  return (
    <div>
      <div className="hidden md:block"></div>
      <div className="mx-auto pb-20">
        <Header />
        <BannerComponent />
        <div className="md:hidden">
          <img src={banner} alt="Banner" className="w-full" />
          <div class="firstsec firstsec-mobile">
            <div className="relative -mt-80 sm:hidden py-10 text-left p-8">
              <h1 className="text-customPurple font-sans text-xl">
                <b>Facilite o Processo dos Conselhos de Classe Profissionais com a Toolbit</b>
              </h1>

              <a href="https://adxcompany.com.br/contato"><button className="mt-9 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
                  SOLICITE SEU DIAGNÓSTICO
              </button></a>
            </div>
          </div>
        </div>
        <div className="bg-customPurple py-10 bg-pattern flex flex-col items-center">
          <div className="items-center md:w-full  md:max-w-[90vw] md:grid md:grid-cols-2 z-40 p-8">
            <div>
              <p className="flex flex-col text-white sm:text-left justify-center h-full sm:pr-10 sm:text-xl">
                A ADX Company, visando aprimorar os processos de rotina dos
                Conselhos de classe profissionais, desenvolveu uma ferramenta
                destinada a simplificar as verificações durante inspeções ou
                visitas de rotina.
                <br />
                <br />
                Os Conselhos de classe profissionais realizam visitas
                preventivas aos estabelecimentos para assegurar que estejam em
                conformidade com as normas. Essas fiscalizações são conduzidas
                por funcionários dos conselhos, que realizam inspeções
                periódicas.
              </p>
            </div>
            <div className="flex flex-col">
              {" "}
              <div>
                <img
                  src={imgconselho}
                  alt="dashboard"
                  className="pt-10 sm:pt-0 z-10 h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white mt-20">
          <p className="sm:text-5xl font-bold text-customPurple">
            Toolbit para conselhos
            <br />
            de classe profissional{" "}
          </p>
          <div className="flex flex-row mx-[20vw] mt-10">
            <div className="flex-row  basis-2/5 justify-end hidden sm:flex">
              <img src={Img1} />
            </div>
            <div className="flex flex-col  basis-3/5 justify-center text-left ml-8">
              <h1 className="text-customPurple text-3xl font-bold">DENÚNCIA</h1>
              <p className="text-customPurple sm:pr-[20vw]">
                A denúncia atualmente pode ser efetuada por meio de e-mail,
                telefone ou WhatsApp. Mas, com a Toolbit, a denúncia poderá ser
                feita de forma anônima, através de um QR code.
              </p>
            </div>
          </div>

          <div className="flex flex-row mx-[20vw] mt-10">
            <div className="flex-row justify-end  basis-2/5 hidden sm:flex">
              <img src={Img2} />
            </div>

            <div className="flex flex-col  basis-3/5 justify-center text-left ml-8 ">
              <h1 className="text-customPurple text-3xl font-bold">
                GESTÃO DE DENÚNCIA
              </h1>
              <ul className="text-customPurple list-disc pl-4">
                <li>
                  Valide cada denúncia; Investig ue cada etapa do processo;
                </li>
                <li>Encaminhe o processo para início do Ato Fiscalizatório;</li>
                <li>Crie agendas para as operações;</li>
                <li>Crie e acompanhe as rotas dos despachantes;</li>
                <li>Crie um protocolo automaticamente;</li>
                <li>Crie etapas para a investigação.</li>
              </ul>
            </div>
          </div>

          <div className="flex flex-row mx-[20vw] mt-10">
            <div className="flex-row justify-end  basis-2/5 hidden sm:flex">
              <img src={Img3} />
            </div>
            <div className="flex flex-col  basis-3/5 justify-center text-left ml-8">
              <h1 className="text-customPurple text-3xl font-bold">
                ATO FISCALIZATÓRIO
              </h1>

              <ul className="text-customPurple list-disc pl-4">
                <li>Checklist com fotos;</li> <li>Funcionamento offline;</li>{" "}
                <li>Geolocalização;</li>
                <li>Self Check-in;</li> <li>Tempo de inspeção.</li>
              </ul>
            </div>
          </div>

          <div className="flex flex-row mx-[20vw] mt-10">
            <div className="flex-row justify-end  basis-2/5 hidden sm:flex">
              <img src={Img4} />
            </div>
            <div className="flex flex-col  basis-3/5 justify-center text-left ml-8">
              <h1 className="text-customPurple text-3xl font-bold">
                Relatório
              </h1>
              <ul className="text-customPurple list-disc pl-4">
              <li>Padrão;</li><li>Customizados;</li> <li>Para orgãos fiscalizatórios.</li></ul>
            </div>
          </div>
        </div>
        <div className="mt-20 flex flex-col items-center">
          {" "}
          <div className="container px-6 md:px-48 md:py-8 z-40">
            <YouTubeVideo videoId="szdTSm4Iw5o" />
            <p className="text-2xl md:py-10 hidden md:block">
            Facilite a Realização de Denúncias de Forma Anônima por meio de QR codes, substituindo os métodos tradicionais como e-mail, telefone ou WhatsApp. A modernização do processo garante maior praticidade e sigilo nas denúncias, tornando-o mais acessível e seguro.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default terceirizacao;
