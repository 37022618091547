import React, { useState, useEffect } from "react";
import img1 from "../../assets/imgs/conselho/1.jpg";
import img2 from "../../assets/imgs/conselho/2.jpg";
import img3 from "../../assets/imgs/conselho/3.jpg";
import img4 from "../../assets/imgs/conselho/4.jpg";

const BannerComponent3 = () => {
  const [currentImage, setCurrentImage] = useState(0);

  // Use the imported images directly
  const images = [img1, img2, img3,img4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <h1 className="hidden sm:block">
      <div
        className="flex items-center justify-start pl-28 relative"
        style={{
          backgroundImage: `url(${images[currentImage]})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          height: "100vh",
          maxHeight: "820px",
          transition: "background-image 1s ease-in-out",
        }}
      >
        <div className="text-left relative">
          <h1 className="text-customPurple font-sans text-4xl">
            <b>Facilite o Processo dos Conselhos<br/>de Classe Profissionais com a Toolbit</b>
          </h1>
          <a href="https://adxcompany.com.br/contato"><button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
            SOLICITE SEU DIAGNÓSTICO
          </button></a>
        </div>
      </div>
    </h1>
  );
};

export default BannerComponent3;
