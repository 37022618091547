import React, { useState, useEffect } from "react";
import img1 from "../../assets/imgs/food/1.jpg";
import img2 from "../../assets/imgs/food/2.jpg";
import img3 from "../../assets/imgs/food/3.jpg";

const BannerComponent = () => {
  const [currentImage, setCurrentImage] = useState(0);

  // Use the imported images directly
  const images = [img1, img2, img3];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % images.length);
    }, 3000); // Change image every 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [images.length]);

  return (
    <h1 className="hidden sm:block">
      <div
        className="flex items-center justify-start pl-28 relative"
        style={{
          backgroundImage: `url(${images[currentImage]})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right center",
          height: "100vh",
          maxHeight: "820px",
          transition: "background-image 1s ease-in-out"
        }}
      >


        <div className="text-left relative">
          <h1 className="text-customPurple font-sans text-4xl pr-[40vw]">
          <b>PLATAFORMA COMPLETA</b> PARA
          <b> GERENCIAR AS OPERAÇÕES</b> DA
          SUA EMPRESA DE <b>REFEIÇÃO</b>
          <b> COLETIVA</b> OU <b>CONSULTORIA ALIMENTAR</b>
          </h1>
          <a href="https://adxcompany.com.br/contato"><button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
            SOLICITE SEU DIAGNÓSTICO
          </button></a>
        </div>
      </div>
    </h1>
  );
};

export default BannerComponent;
