import React from "react";
import banner from "../../assets/imgs/Vigilante-Patrimonial.jpg";
import BannerComponent from "../../components/rotatebanner/bannercomponent";
import imgterceirizacao from "../../assets/imgs/dashboard.png";
import Footer from "../../components/footer/footer";
import Header from "../../components/header/header";
import YouTubeVideo from "../../components/youtubevideo";
import ContinuousSlider from "./sliderdesk";
import imgnote from "../../assets/imgs/terceirizacao/21.png";
import Slidermobile from "./slidermobile";
import Svg from "../../assets/imgs/terceirizacao/svg.svg";
import svg1 from "../../assets/imgs/divmobile.svg";
import svg2 from "../../assets/imgs/CHECKLIST.svg";
import svg3 from "../../assets/imgs/RDO.svg";
import svg4 from "../../assets/imgs/RELATORIOS.svg";
import svg5 from "../../assets/imgs/RASTREABILIDADE.svg";
import svg6 from "../../assets/imgs/LOCALIZAÇÃO.svg";
import svg7 from "../../assets/imgs/OFFLINE.svg";

const terceirizacao = () => {
  return (
    <div>
      <div className="hidden md:block"></div>
      <div className="mx-auto pb-20">
        <Header />
        <BannerComponent />
        <div className="md:hidden">
          <img src={banner} alt="Banner" className="w-full" />
          <div class="firstsec firstsec-mobile">
            <div className="relative -mt-80 sm:hidden py-10 text-left p-8">
              <h1 className="text-customPurple font-sans text-xl">
                <b>VOCÊ SABE</b>
                <br />
                ONDE SUA EQUIPE
                <br />
                <b>ESTÁ AGORA?</b>
              </h1>
              <p className="pt-4 text-customPurple">
                A Toolbit é a melhor e mais
                <br />
                completa tecnologia do mercado, e<br />
                estamos 100% focados no setor de <br />
                terceirização de serviços e<br />
                vigilância patrimonial
              </p>
              <a href="https://adxcompany.com.br/contato"><button className="mt-5 bg-purple-700 rounded-full py-3 px-6 text-white font-bold">
                
                  SOLICITE SEU DIAGNÓSTICO
                
              </button></a>
            </div>
          </div>
        </div>
        <div className="bg-customPurple py-10 bg-pattern flex flex-col items-center">
          <div className="items-center md:w-full  md:max-w-[90vw] md:grid md:grid-cols-2 z-40 p-8">
            <div>
              <p className="flex flex-col text-white sm:text-left justify-center h-full sm:pr-10 text-xl">
                O nosso software acompanha qualquer tipo de atividade executada
                pelos seus colaboradores, tudo isso por meio de uma ferramenta
                customizável e capaz de atender todas as necessidades dos seus
                clientes.
              </p>
            </div>
            <div className="flex flex-col">
              {" "}
              <div>
                <img
                  src={imgterceirizacao}
                  alt="dashboard"
                  className="pt-10 sm:pt-0 z-10 h-full w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100 py-8 ">
          <h1 className="text-2xl font-bold text-customPurple mb-4 sm:text-4xl">
            São mais de 28 Funcionalidades
            <br />
            em um único software{" "}
          </h1>
          <p className="mt-2 text-black font-normal sm:text-3xl">
            As principais são:
          </p>
        </div>
        <div className="bg-gray-100 p-4 sm:py-14">
          <div className="max-w-[90vw] items-center mx-auto mt-6 space-y-4 px-3 md:space-y-0 md:grid md:grid-cols-2 md:gap-4 text-left pb-4">
            <div className="flex items-center bg-white p-4 rounded-lg shadow">
              
              <div className="text-customPurple font-semibold sm:text-xl">
                CHECKLISTS CUSTOMIZÁVEIS
              </div>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow">
            
              <div className="text-customPurple font-semibold sm:text-xl">
                LOCALIZAÇÃO E MOVIMENTAÇÃO DOS FUNCIONÁRIOS
              </div>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow">
              
              <div className="text-customPurple font-semibold sm:text-xl">
                REGISTRO E ACOMPANHAMENTO DE OCORRÊNCIAS
              </div>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow">
              
              <div className="text-customPurple font-semibold sm:text-xl">
                RONDAS
              </div>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow">
             
              <div className="text-customPurple font-semibold sm:text-xl">
                INTEGRAÇÃO COM SEU ERP
              </div>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow ">
             
              <div className="text-customPurple font-semibold sm:text-xl">
                RELATÓRIOS, GRÁFICOS E INDICADORES
              </div>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow ">
             
              <div className="text-customPurple font-semibold sm:text-xl">
                CHECKLISTS PARA GESTÃO DE EXECUÇÃO DE ATIVIDADES
              </div>
            </div>
            <div className="flex items-center bg-white p-4 rounded-lg shadow ">
             
              <div className="text-customPurple font-semibold sm:text-xl">
                CONTROLE DE ITENS UTILIZADOS NAS OPERAÇÕES
              </div>
            </div>
          </div>

        </div>

        <h1 className="m-8 text-2xl font-bold text-customPurple mb-4 mt-20 sm:text-4xl">
          TOOLBIT
          <br /> CLIENT
        </h1>
        <p className="px-5 mx-4 md:mx-[10vw] sm:text-3xl">
          A Helpdesk da toolbit client otimizará a interação entre a sua empresa
          e seus clientes. Além de permitir um canal de comunicação interna na
          sua empresa.
        </p>
        <div className="flex flex-col bg-gray-300 items-center pb-10 mt-24">
          <img
            src={imgnote}
            alt="ADX Dashboard"
            className="h-[40vw] md:h-60 w-auto -mt-14"
          />
        </div>
        <div className="hidden md:block mt-20">
          <h1 className="font-bold text-5xl text-customPurple">
            Descubra as funcionalidades
            <br />
            do sistema Toolbit
          </h1>
          <div className="flex flex-row justify-center gap-5">
            <p className="text-2xl text-customPurple self-center">
              Arraste para o lado
            </p>
            <img
              src={Svg}
              className="transition-transform duration-500 hover:rotate-45"
            />
          </div>
        </div>
        <div className="hidden md:block">
          {" "}
          <ContinuousSlider />
        </div>
        <div className="sm:hidden block pt-20">
          <Slidermobile />
        </div>

        <div className="mt-10 md:-mt-0 flex flex-col items-center">
          <div className="container px-6 md:px-48 md:py-8 z-40">
            {" "}
            <YouTubeVideo videoId="LV2-cjRpzfU" />
            <p className="sm:text-2xl py-10">
              Tenha 100% dos seus clientes atendidos e assegure a qualidade dos
              seus serviços executados.
              <br />A utilização dos nossos softwares trará{" "}
              <span className="text-customPurple font-bold">
                economia nas operações, aumento de produtividade dos
                colaboradores e ainda mais qualidade
                <br />
                nos serviços prestados.
              </span>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default terceirizacao;
