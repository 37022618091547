import "./App.css";
import Home from "./pages/home/home";
import Terceirizacao from "./pages/terceirizacao/terceirizacao";
import Food from "./pages/food/food";
import Gestao from "./pages/gestao/gestao";
import Indiqueganhe from "./pages/indiqueganhe/indiqueganhe";
import Conselho from "./pages/conselho/conselho";
import Contato from "./pages/contato/contato";
import Sobre from "./pages/sobre/sobre";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

function App() {
  return (
    <div className="App">

      <Router>
      <div>
        <Routes> 
          <Route path="/" element={<Home />} />
          <Route path="/terceirizacao" element={<Terceirizacao />} />
          <Route path="/food" element={<Food />} />
          <Route path="/gestao" element={<Gestao />} />
          <Route path="/indiqueganhe" element={<Indiqueganhe />} />
          <Route path="/Conselho" element={<Conselho />} />
          <Route path="/Contato" element={<Contato />} />
          <Route path="/Sobre" element={<Sobre />} />


          {/*<Route path="/newpage" element={<Page/>} />*/}
        </Routes>
      </div>
    </Router>
 

    </div>
  );
}

export default App;
